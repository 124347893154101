import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { HourService } from "../services/hour.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Card, HourStateModel, teamProject } from "../interfaces/hours";
import { CameraComponent } from "../pages/add-hour/component/camera/camera.component";
import { CookieService } from "ngx-cookie-service";
import { User } from "../interfaces/user";
import { calculateWidth } from "../helpers/calculate-modal-width";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "src/environments/environment";
import { tap } from "rxjs";

export class AddHour {
  static readonly type = "[Hour] Add Hour";
  constructor(public card: Card) {}
}

export class FinalizeHour {
  static readonly type = "[Hour] Finalize Hour";
  constructor(public cardId: string, public hourId: string) {}
}

export class RefreshUserCard {
  static readonly type = "[Hours] Refresh User Card";
  constructor(public userId: string) {}
}

export class CreateProject {
  static readonly type = "[Hours] CreateProject";
  constructor(public name: string) {}
}

export class GetProjects {
  static readonly type = "[Hours] GetProjects";
  constructor() {}
}

export class EditProject {
  static readonly type = "[Hours] EditProject]";
  constructor(public readonly id: string, public readonly name: string) {}
}

const teamProjectCollection = environment.production
  ? "teamProject"
  : "dev-teamProject";
@State<HourStateModel>({
  name: "hours",
  defaults: {
    id: null,
    cards: [],
    card: null,
    filteredProjects: [],
  },
})
@Injectable()
export class HourState {
  constructor(
    private hourService: HourService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private afs: AngularFirestore
  ) {}

  @Selector()
  static getUserCards({ card }: HourStateModel) {
    return card;
  }

  @Selector()
  static getProjects(state: HourStateModel): teamProject[] {
    return state.filteredProjects;
  }

  @Action(AddHour)
  async addHour({}: StateContext<HourStateModel>, { card }: AddHour) {
    const user: User = JSON.parse(this.cookieService.get("user"));

    if (!card.project) {
      this.snack.open("No se seleccionó ningún proyecto.", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
      return;
    }

    const dialogRef = this.dialog.open(CameraComponent, {
      maxWidth: calculateWidth(),
      width: calculateWidth(),
    });

    dialogRef.afterClosed().subscribe(async (imageUrl: string | null) => {
      if (!imageUrl) {
        this.snack.open("Captura de imagen fallida o cancelada.", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        return;
      }

      try {
        const existingCard = await this.hourService
          .getCardByUserId(user.id)
          .toPromise();

        if (existingCard) {
          await this.hourService.addHourToCard(
            existingCard.id,
            imageUrl,
            card.project
          );
          this.snack.open(
            "Hora agregada correctamente al card existente.",
            "Ok",
            {
              duration: 3000,
              panelClass: ["snackbar"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            }
          );
        } else {
          await this.hourService.createCardWithHour(
            user.id,
            imageUrl,
            card.project
          );
          this.snack.open(
            "Card, hora y proyecto registrados correctamente.",
            "Ok",
            {
              duration: 3000,
              panelClass: ["snackbar"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            }
          );
        }
      } catch (error) {
        this.snack.open("Error al registrar la hora y el proyecto.", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    });
  }

  @Action(FinalizeHour)
  async finalizeHour(
    {}: StateContext<HourStateModel>,
    { cardId, hourId }: FinalizeHour
  ) {
    try {
      const dialogRef = this.dialog.open(CameraComponent, {
        maxWidth: calculateWidth(),
        width: calculateWidth(),
      });

      dialogRef.afterClosed().subscribe(async (imageUrl: string | null) => {
        if (!imageUrl) {
          this.snack.open("Captura de imagen fallida o cancelada.", "Ok", {
            duration: 3000,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
          return;
        }
        try {
          const finishedAt = new Date().toLocaleString("en-GB");

          await this.hourService.finalizeHour(
            cardId,
            hourId,
            finishedAt,
            imageUrl
          );

          this.snack.open("Salida registrada correctamente.", "Ok", {
            duration: 3000,
            panelClass: ["snackbar"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        } catch (error) {
          this.snack.open("Error al finalizar el día.", "Ok", {
            duration: 3000,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }
      });
    } catch (error) {
      console.error("Error finalizing hour:", error);
      this.snack.open("Error al finalizar el día.", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }

  @Action(CreateProject)
  async createProject(
    {}: StateContext<HourStateModel>,
    { name }: CreateProject
  ) {
    const id = this.afs.createId();
    const date = new Date();
    const createdAt = date.toLocaleString("en-GB");
    const user: User = JSON.parse(this.cookieService.get("user"));
    const newProject: teamProject = {
      id,
      name,
      createdAt,
      createdBy: user.username,
    };

    try {
      await this.afs.collection(teamProjectCollection).doc(id).set(newProject);

      this.snack.open("¡Proyecto creado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch (error) {
      this.snack.open("Error al crear el proyecto", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
      console.error("Error al crear el proyecto:", error);
    }
  }

  @Action(GetProjects)
  getUser(
    { patchState, getState }: StateContext<HourStateModel>,
    {}: GetProjects
  ) {
    const { filteredProjects } = getState();
    if (!filteredProjects?.length)
      return this.afs
        .collection<teamProject>(teamProjectCollection)
        .valueChanges()
        .pipe(tap((project) => patchState({ filteredProjects: project })));
    return null;
  }

  @Action(EditProject)
  async editProject(
    { patchState }: StateContext<HourStateModel>,
    { id, name }: EditProject
  ) {
    const date = new Date();
    const updatedAt = date.toLocaleString("en-GB");
    const user: User = JSON.parse(this.cookieService.get("user"));

    try {
      const projectRef = this.afs.collection(teamProjectCollection).doc(id);
      await projectRef.update({
        name,
        updatedAt,
        updatedBy: user.username,
      });

      this.snack.open("¡Proyecto editado correctamente!", "Ok", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch (error) {
      this.snack.open("Error al editar el proyecto", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
      console.error("Error al editar el proyecto:", error);
    }
  }
}
