import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Card, Hour, teamProject } from "../interfaces/hours";
import { environment } from "src/environments/environment";
import { map, Observable } from "rxjs";
import * as moment from "moment";
import { User } from "../interfaces/user";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class HourService {
  private readonly collectionName = environment.production
    ? "cards"
    : "dev-cards";

  constructor(
    private firestore: AngularFirestore,
    private cookieService: CookieService
  ) {}

  async createCardWithHour(
    userId: string,
    photoUrl: string,
    project: teamProject
  ): Promise<void> {
    const dateform = new Date();
    const id = this.firestore.createId();

    const isLate = !this.checkOnTime(dateform);

    const newHour: Hour = {
      id,
      createdAt: dateform.toLocaleString("en-GB"),
      photoUrl,
      project,
      late: isLate,
    };

    const newCard: Card = {
      id,
      userId,
      createdBy: userId,
      createdAt: dateform.toLocaleString("en-GB"),
      hours: [newHour],
      project,
    };

    await this.firestore.collection(this.collectionName).doc(id).set(newCard);
  }

  async addHourToCard(
    cardId: string,
    photoUrl: string,
    project: teamProject
  ): Promise<void> {
    const dateform = new Date();
    const id = this.firestore.createId();

    const isLate = !this.checkOnTime(dateform);

    const newHour: Hour = {
      id,
      createdAt: dateform.toLocaleString("en-GB"),
      photoUrl,
      project,
      late: isLate,
    };

    const cardRef = this.firestore.collection(this.collectionName).doc(cardId);
    try {
      const cardSnapshot = await cardRef.get().toPromise();
      const card = cardSnapshot.exists ? (cardSnapshot.data() as Card) : null;

      if (card) {
        const updatedHours = card.hours ? [...card.hours, newHour] : [newHour];
        await cardRef.update({ hours: updatedHours });
      }
    } catch (error) {
      console.error("Error getting card:", error);
    }
  }

  async finalizeHour(
    cardId: string,
    hourId: string,
    finishedAt: string,
    photoUrlFinished: string
  ): Promise<void> {
    const cardRef = this.firestore.collection(this.collectionName).doc(cardId);

    try {
      const user: User = JSON.parse(this.cookieService.get("user"));

      const cardSnapshot = await cardRef.get().toPromise();
      const card = cardSnapshot.exists ? (cardSnapshot.data() as Card) : null;

      if (card && card.hours) {
        const hour = card.hours.find((h) => h.id === hourId);

        if (!hour) {
          throw new Error("No se encontró la hora especificada.");
        }

        const dateFormat = "DD/MM/YYYY, HH:mm:ss";
        const createdAtMoment = moment(hour.createdAt, dateFormat);
        const finishedAtMoment = moment(finishedAt, dateFormat);

        if (!createdAtMoment.isValid() || !finishedAtMoment.isValid()) {
          throw new Error("Las fechas ingresadas no son válidas.");
        }

        const totalDurationInMinutes = finishedAtMoment.diff(
          createdAtMoment,
          "minutes"
        );

        let regularHours = Math.floor(totalDurationInMinutes / 60);
        let regularMinutes = totalDurationInMinutes % 60;

        if (regularHours > 8) {
          regularHours = 8;
          regularMinutes = 0;
        }

        let extraHours = 0;
        let extraMinutes = 0;

        if (totalDurationInMinutes > 480) {
          if (totalDurationInMinutes > 540) {
            const extraDurationInMinutes = totalDurationInMinutes - 540;
            extraHours = Math.floor(extraDurationInMinutes / 60);
            extraMinutes = extraDurationInMinutes % 60;
          }
        }

        hour.finishedAt = finishedAt;
        hour.photoUrlFinished = photoUrlFinished;
        hour.regularHours = regularHours;
        hour.regularMinutes = regularMinutes;
        hour.extraHours = extraHours;
        hour.extraMinutes = extraMinutes;

        const dateform = new Date();
        card.updatedAt = dateform.toLocaleString("en-GB");
        card.updatedBy = user.id;

        await cardRef.update({
          hours: card.hours,
          updatedAt: card.updatedAt,
          updatedBy: card.updatedBy,
        });
      } else {
        throw new Error("No se encontró la tarjeta.");
      }
    } catch (error) {
      console.error("Error finalizando la hora:", error);
      throw error;
    }
  }

  async editHour(
    cardId: string,
    hourId: string,
    newCreatedAt: string | null,
    newFinishedAt: string | null,
    project: teamProject | null
  ): Promise<void> {
    const cardRef = this.firestore.collection(this.collectionName).doc(cardId);

    try {
      const user: User = JSON.parse(this.cookieService.get("user"));

      const cardSnapshot = await cardRef.get().toPromise();
      const card = cardSnapshot.exists ? (cardSnapshot.data() as Card) : null;

      if (card && card.hours) {
        const hour = card.hours.find((h) => h.id === hourId);

        if (!hour) {
          throw new Error("No se encontró la hora especificada.");
        }

        if (project) {
          hour.project = project;
        }

        if (newCreatedAt === null) {
          throw new Error("La nueva hora de entrada no puede ser nula.");
        }

        const dateFormat = "DD/MM/YYYY, HH:mm:ss";
        const createdAtMoment = moment(newCreatedAt, dateFormat);

        if (!createdAtMoment.isValid()) {
          throw new Error(
            `La nueva hora de entrada no es válida: ${newCreatedAt}`
          );
        }

        if (newFinishedAt === null) {
          hour.createdAt = newCreatedAt;
          hour.finishedAt = null;
          hour.photoUrlFinished = null;
          hour.extraHours = 0;
          hour.extraMinutes = 0;
          hour.regularHours = 0;
          hour.regularMinutes = 0;
          const createdAtDate = createdAtMoment.toDate();
          hour.late = !this.checkOnTime(createdAtDate);
        } else {
          const finishedAtMoment = moment(newFinishedAt, dateFormat);

          if (!finishedAtMoment.isValid()) {
            throw new Error(
              `La nueva hora de salida no es válida: ${newFinishedAt}`
            );
          }

          const newDate = createdAtMoment.format("YYYY-MM-DD");
          const sameDayHours = card.hours.filter(
            (h) =>
              h.id !== hourId &&
              h.createdAt &&
              moment(h.createdAt, dateFormat).format("YYYY-MM-DD") === newDate
          );

          for (const otherHour of sameDayHours) {
            const otherFinishedAt = otherHour.finishedAt
              ? moment(otherHour.finishedAt, dateFormat)
              : null;

            if (otherFinishedAt && createdAtMoment.isBefore(otherFinishedAt)) {
              throw new Error(
                "La nueva hora de entrada no puede ser menor a una hora de salida del mismo día."
              );
            }
          }

          const totalDurationInMinutes = finishedAtMoment.diff(
            createdAtMoment,
            "minutes"
          );

          let regularHours = Math.floor(totalDurationInMinutes / 60);
          let regularMinutes = totalDurationInMinutes % 60;

          if (regularHours > 8) {
            regularHours = 8;
            regularMinutes = 0;
          }

          let extraHours = 0;
          let extraMinutes = 0;

          if (totalDurationInMinutes > 480) {
            if (totalDurationInMinutes > 540) {
              const extraDurationInMinutes = totalDurationInMinutes - 540;
              extraHours = Math.floor(extraDurationInMinutes / 60);
              extraMinutes = extraDurationInMinutes % 60;
            }
          }

          hour.createdAt = newCreatedAt;
          hour.finishedAt = newFinishedAt;
          hour.regularHours = regularHours;
          hour.regularMinutes = regularMinutes;
          hour.extraHours = extraHours;
          hour.extraMinutes = extraMinutes;

          const createdAtDate = createdAtMoment.toDate();
          hour.late = !this.checkOnTime(createdAtDate);
        }

        const dateform = new Date();
        card.updatedAt = dateform.toLocaleString("en-GB");
        card.updatedBy = user.id;

        await cardRef.update({
          hours: card.hours,
          updatedAt: card.updatedAt,
          updatedBy: card.updatedBy,
        });
      } else {
        throw new Error("No se encontró la tarjeta.");
      }
    } catch (error) {
      console.error("Error editando la hora:", error);
      throw error;
    }
  }

  getCard(userId: string): Observable<Card | null> {
    return this.firestore
      .collection(this.collectionName, (ref) =>
        ref.where("userId", "==", userId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          if (actions.length > 0) {
            const cardDoc = actions[0].payload.doc.data() as Card;
            cardDoc.id = actions[0].payload.doc.id;
            return cardDoc;
          }
          return null;
        })
      );
  }

  getCardByUserId(userId: string): Observable<Card | null> {
    return this.firestore
      .collection(this.collectionName, (ref) =>
        ref.where("userId", "==", userId)
      )
      .get()
      .pipe(
        map((querySnapshot) => {
          if (!querySnapshot.empty) {
            const cardDoc = querySnapshot.docs[0];
            return cardDoc.data() as Card;
          }
          return null;
        })
      );
  }

  private checkOnTime(timestamp: Date): boolean {
    const minutes = timestamp.getMinutes();
    return (minutes >= 55 && minutes <= 59) || (minutes >= 0 && minutes <= 5);
  }
}
